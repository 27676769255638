
  import { Component, Prop } from 'vue-property-decorator'
  import { FilesProcess } from '@/components/forms/view/FilesProcess'
  import { FileProcess } from '@/entities/files'

@Component
  export default class laborDataFiles extends FilesProcess {
  @Prop({ type: Number, default: null }) id: number

  filesProcess: FileProcess[] = []
  displayFiles: FilesProcess[] = []

  async mounted () {
    const process = await this.fetchData({
      query: { name: 'find', model: 'Process' },
      filter: { table_name: { _eq: 'labor_data' } },
    })
    await this.setFile(this.id, process[0].id)
  }

  // Methods
  async setFile (id, idProcess) {
    if (!id && !idProcess) return
    this.filesProcess = await this.fetchData({
      query: { name: 'find', model: 'FileProcess' },
      filter: {
        _and: [
          { id_process_record: { _eq: id } },
          { parameter: { process: { id: { _eq: idProcess } } } },
        ],
      },
      force: true,
    })
    this.displayFiles = this.filesProcess.filter(file => file.parameter.name !== 'latest_settlements')
    this.displayFiles.push(this.filesProcess.filter(file => file.parameter.name === 'latest_settlements')[0])
    this.displayFiles = this.displayFiles.filter(Boolean)
  }

  openFile (fileProcess) {
    if (fileProcess.parameter.name === 'latest_settlements') {
      this.filesProcess.filter(file => file.parameter.name === 'latest_settlements').map(file => {
        this.openLink(file.file.uri)
      })
    } else {
      this.openLink(fileProcess.file.uri)
    }
  }

  // Getters

  // Watchers
  }
